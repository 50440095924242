
import { Component, Prop, Vue } from 'vue-property-decorator';
import 'vue-svg-map/dist/index.css';
// import { CheckboxSvgMap, SvgMap } from 'vue-svg-map';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { CheckboxSvgMap, SvgMap } = require('vue-svg-map');
@Component({
  components: {
    CheckboxSvgMap,
    SvgMap,
  },
})
export default class DemoMap extends Vue {
  @Prop() surveyIdProp!: string;
  @Prop() region!: string[];
  @Prop() editable?: boolean;
  private map: any = null;
  private selectedRegion: string[] = [];

  async created(): Promise<void> {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  async load(): Promise<void> {
    const { data: project } = await this.axios({
      url: `/project/${ this.surveyIdProp }`,
    });
    if (this.region) this.selectedRegion = this.region;
    const { country: [country] } = project;
    const { data } = await this.axios({
      url: `/project/enums/${ this.surveyIdProp }`,
    });
    const { maps } = data as { region: string[], maps: any };
    if (maps[country]) this.map = maps[country];
  }

  selected(value: string[]): void {
    this.$emit('selected', value);
  }

  parentSelect(value: string[]): void {
    this.selectedRegion = value;
  }
}
